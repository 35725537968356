<template>
	<a-modal title="设备租用" :visible="visible" ok-text="提交" cancel-text="取消" @ok="submitForm" @cancel="handleCancel">
		<a-form-model :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" ref="Form" :model="form" :rules="Rules">
			<a-form-model-item prop="time" label="租用时间">
				<a-input v-model="form.time">分钟</a-input>
			</a-form-model-item>
		</a-form-model>
	</a-modal>
</template>

<script>
export default {
	props: {
		equipment_id: {
			type: [String, Number],
			default: ''
		}
	},
	data() {
		return {
			confirmLoading: false,
			visible: false,
			form: {
				time: ''
			},
			Rules: {
				time: [
					{
						required: true,
						message: '请输入时间',
						trigger: 'blur'
					}
				]
			}
		}
	},
	created() { },
	methods: {
		handleCancel() {
			this.initForm()
		},
		initForm() {
			this.visible = false
			this.form = {
				time: ''
			}
			this.equipment_id = null
		},
		submitForm() {
			this.$refs.Form.validate(valid => {
				if (valid) {
					this.confirmLoading = true
					this.$post('equipment/equipmentRent', {
						'equipment_id': this.equipment_id,
						'time': this.form.time,
						'type': 1
					})
						.then(res => {
							let { code, msg, data } = res
							this.confirmLoading = false
							if (code == 0) {
								this.$message.success(msg, 1.5)
								this.initForm()
								this.$emit('success')
							} else {
								this.$message.error(msg, 1.5)
							}
						})
						.catch(err => {
							this.confirmLoading = false
						})
				}
			})
		}
	}
}
</script>

<style lang="less" scoped></style>
