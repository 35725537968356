<template>
  <a-modal :visible="visible" title="分红设置" @ok="submitForm" @cancel="modalHide">
    <div class="tips">按各角色名下绑定设备总营业额百分比计算；0代表不参与分红</div>
    <a-form-model ref="shareForm" :model="formData" :rules="rules" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
      <a-form-model-item label="平台">
        <a-input-number style="width: 100%" v-model="formData.platform" :min="0" :max="100"></a-input-number>
      </a-form-model-item>
      <a-form-model-item label="代理商">
        <a-input-number style="width: 100%" v-model="formData.agent" :min="0" :max="100"></a-input-number>
      </a-form-model-item>
      <a-form-model-item label="医院">
        <a-input-number style="width: 100%" v-model="formData.hospital" :min="0" :max="100"></a-input-number>
      </a-form-model-item>
      <a-form-model-item label="护士">
        <a-input-number style="width: 100%" v-model="formData.nurse" :min="0" :max="100"></a-input-number>
      </a-form-model-item>
      <a-form-model-item label="运营">
        <a-input-number style="width: 100%" v-model="formData.operator" :min="0" :max="100"></a-input-number>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
// 把接口放在一个文件里进行维护了,
import { shareMoneyConfig, shareMoneyConfigInfo } from '@/api/equipment.js'
export default {
  props: {
    equipment_id: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      visible: false,
      formData: {
        platform: 0,
        agent: 0,
        hospital: 0,
        nurse: 0,
        operator: 0
      },
      moneyPlanList: [],
      rules: {
        platform: [{ required: true, message: '请输入平台分红', trigger: 'change' }],
        agent: [{ required: true, message: '请输入代理商分红', trigger: 'change' }],
        hospital: [{ required: true, message: '请输入医院分红', trigger: 'change' }],
        nurse: [{ required: true, message: '请输入护士分红', trigger: 'change' }],
        operator: [{ required: true, message: '请输入运营分红', trigger: 'change' }]
      }
    }
  },
  watch: {
    equipment_id(value) {
      this.formData.equipment_id = value
      if (value) {
        this.formData = {
          equipment_id: value,
          platform: 0,
          agent: 0,
          hospital: 0,
          nurse: 0,
          operator: 0
        }
        this.getInfo(value)
      }
    }
  },
  async created() {
    // 这里
  },
  mounted() { },
  methods: {
    modalHide() {
      this.visible = false
      this.getInfo(this.equipment_id)
    },
    async getInfo(id) {
      const res = await shareMoneyConfigInfo({ equipment_id: id })
      if (res.code === 0) {
        this.formData.platform = Number(res.data.platform)
        this.formData.agent = Number(res.data.agent)
        this.formData.hospital = Number(res.data.hospital)
        this.formData.nurse = Number(res.data.nurse)
        this.formData.operator = Number(res.data.operator)
      }
    },
    confirmSubmit() { },
    submitForm() {
      this.$refs.shareForm.validate(async valid => {
        if (valid) {
          let total =
            this.formData.platform +
            this.formData.agent +
            this.formData.hospital +
            this.formData.nurse +
            this.formData.operator
          if (total !== 100) {
            this.$message.warning('请合理分配分红方式')
            return false
          }
          const res = await shareMoneyConfig({ ...this.formData })
          if (res.code === 0) {
            this.visible = false
            this.$message.success('分红设置', 1.5)
          } else {
            this.$message.success(res.msg || '分红设置失败', 1.5)
          }
          this.$emit('updateData')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped></style>
