import { domain } from '@/siteInfo'
console.log('请求接口里的', domain)
import { fetch } from '@/utils/fetchs.js'
// 医院列表
export const hospitalList = (params = {}) => fetch(domain + '/admin/v1/hospital/hospitalList', params)

// 医院选择器
export const hospitalLSelect = (params = {}) => fetch(domain + '/admin/v1/hospital/hospitalSelect', params)

// 科室列表
export const departmentList = (params = {}) => fetch(domain + '/admin/v1/hospital/departmentList', params)
