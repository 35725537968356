<template>
  <a-modal title="设备配置" :visible="visible" ok-text="提交" cancel-text="取消" @ok="submitForm" @cancel="handleCancel"
    :confirmLoading="subing">
    <a-form-model :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" ref="form" :model="form" :rules="rules">
      <h3>温度配置</h3>
      <a-form-model-item prop="is_wd" label="温度：">
        <a-radio-group name="radioGroup" v-model="form.is_wd">
          <a-radio :value="-1"> 关闭 </a-radio>
          <a-radio :value="1"> 低温 </a-radio>
          <a-radio :value="2"> 高温 </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item prop="is_sound" label="声音：">
        <a-switch checked-children="开" un-checked-children="关" v-model="form.is_sound" />
      </a-form-model-item>
      <a-form-model-item prop="is_mechanism_set" label="应用科室配置：">
        <a-switch @change="keshiChange" checked-children="开" un-checked-children="关" v-model="form.is_mechanism_set" />
      </a-form-model-item>
      <template v-if="type == 1">
        <h3>WIFI配置</h3>
        <a-form-model-item prop="wifi_name" label="WIFI账号：">
          <a-input v-model="form.wifi_name"></a-input>
        </a-form-model-item>
        <a-form-model-item prop="wifi_password" label="WIFI密码：">
          <a-input v-model="form.wifi_password"></a-input>
        </a-form-model-item>
        <a-form-model-item prop="is_mechanism_wifi" label="应用科室配置：">
          <a-switch @change="wifiChange" checked-children="开" un-checked-children="关" v-model="form.is_mechanism_wifi" />
        </a-form-model-item>
      </template>
    </a-form-model>
  </a-modal>
</template>

<script>
import { equipmentConfig, equipmentConfigInfo } from '@/api/equipment'
export default {
  props: {
    equipment_id: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      visible: false,
      form: {
        is_wd: '',
        is_sound: false,
        is_mechanism_set: false,
        wifi_name: '',
        wifi_password: '',
        is_mechanism_wifi: false
      },
      type: null,
      subing: false,
      rules: {
        is_wd: [
          {
            required: true,
            message: '请选择温度',
            trigger: 'change'
          }
        ],
        is_sound: [
          {
            required: true,
            message: '请选择声音',
            trigger: 'change'
          }
        ],
        wifi_name: [
          {
            required: true,
            message: '请输入wifi名称',
            trigger: 'blur'
          }
        ],
        wifi_password: [
          {
            required: true,
            message: '请输入wifi密码',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  watch: {
    equipment_id(val) {
      this.getSetInfo(val)
    }

  },
  created() {

  },
  methods: {
    async getSetInfo() {
      console.log('更新id:', this.equipment_id)
      const res = await equipmentConfigInfo({ equipment_id: this.equipment_id })
      console.log(res.data)
      if (res.code === 0) {
        this.form.is_wd = res.data.is_wd
        this.form.is_sound = res.data.is_sound
        this.form.is_mechanism_set = res.data.is_mechanism_set
        this.form.wifi_name = res.data.wifi_name
        this.form.wifi_password = res.data.wifi_password
        this.form.is_mechanism_wifi = res.data.is_mechanism_wifi
        this.type = res.data.type
        this.keshiChange(this.form.is_mechanism_set)
        this.wifiChange(this.form.is_mechanism_wifi)
      }
    },
    submitForm() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.subing = true
          let params = { ...this.form }
          params.equipment_id = this.equipment_id
          const res = await equipmentConfig(params)
          this.subing = false
          if (res.code === 0) {
            this.$message.success('设备配置成功', 1.5)
            this.$emit('success')
            this.visible = false
          } else {
            this.$message.error(res.msg || '配置失败', 1.5)
          }
        }
      })
    },
    handleCancel() {
      this.visible = false
      // this.$refs.form.resetFields()
    },
    keshiChange(e) {
      console.log(e)
      if (e) {
        this.rules.is_wd[0].required = false
        this.rules.is_sound[0].required = false
      } else {
        this.rules.is_wd[0].required = true
        this.rules.is_sound[0].required = true
      }
    },
    wifiChange(e) {
      if (e) {
        this.rules.wifi_name[0].required = false
        this.rules.wifi_password[0].required = false
      } else {
        this.rules.wifi_name[0].wifi_name = true
        this.rules.wifi_password[0].required = true
      }
    }
  }
}
</script>

<style lang="less" scoped></style>
