<template>
	<a-modal :title="(equipment_id ? '编辑' : '新增') + '设备'" :visible="visible" ok-text="提交" cancel-text="取消"
		:confirm-loading="confirmLoading" @ok="submitInfo" @cancel="handleCancel">
		<a-form-model :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" ref="equipmentForm" :model="equipment_info"
			:rules="info_rules">
			<a-form-model-item prop="equipment_number" label="设备编号：">
				<a-input v-model="equipment_info.equipment_number" :disabled="showType">
				</a-input>
			</a-form-model-item>
			<a-form-model-item prop="hardware_number" label="硬件编号：">
				<a-input v-model="equipment_info.hardware_number"></a-input>
			</a-form-model-item>
			<a-form-model-item prop="type" label="设备类型：">
				<a-select placeholder="请选择" v-model="equipment_info.type">
					<a-select-option :value="item.class_id" v-for="item in classListNoPage" :key="item.class_id">
						{{ item.name }}
					</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item prop="remark" label="备注：">
				<a-textarea v-model="equipment_info.remark"></a-textarea>
			</a-form-model-item>
		</a-form-model>
	</a-modal>
</template>

<script>
export default {
	props: {
		equipment_id: {
			type: [String, Number],
			default: ''
		},
		showType: {
			type: Boolean,
			default: false
		},
		info: {
			type: Object,
			default() {
				return {}
			}
		},
		classList: {
			type: Array,
			default() {
				return {}
			}
		},
	},
	data() {
		return {
			visible: false,
			confirmLoading: false,
			equipment_info: {
				equipment_number: '',
				hardware_number: '',
				type: 1,
				remark: ''
			},
			classListNoPage: [],
			info_rules: {
				equipment_number: [
					{
						required: true,
						message: '填写设备编号',
						trigger: 'blur'
					}
				],
				hardware_number: [
					{
						required: true,
						message: '填写硬件编号',
						trigger: 'blur'
					}
				],
				type: [
					{
						required: true,
						message: '请选择设备类型',
						trigger: 'blur'
					}
				],
			}
		}
	},
	watch: {
		info: {
			handler(val) {
				this.equipment_info = val
				this.equipment_info.type = val.type.toString()
			},
			deep: true,
			immediate: true
		},
		classList: {
			handler(val) {
				console.log("检测了啥", this.classListNoPage)
				this.classListNoPage = val
			},
			deep: true,
			immediate: true
		}
	},
	created() {

	},
	methods: {
		submitInfo() {
			this.$refs.equipmentForm.validate(valid => {
				if (valid) {
					this.confirmLoading = true
					let url = this.equipment_id ? 'equipmentEdit' : 'equipmentAdd'
					if (this.equipment_id) {
						this.equipment_info.equipment_id = this.equipment_id
					}
					this.$post('equipment/' + url, this.equipment_info)
						.then(res => {
							let { code, msg, data } = res
							this.confirmLoading = false
							if (code == 0) {
								this.$message.success(msg, 1.5)
								this.handleCancel()
							} else {
								this.$message.error(msg, 1.5)
							}
						})
						.catch(err => {
							this.confirmLoading = false
						})
				}
			})
		},
		handleCancel() {
			this.visible = false
			this.$refs.equipmentForm.resetFields()
			this.$emit('success')
		}
	}
}
</script>

<style lang="less" scoped></style>
