<template>
	<a-modal :visible="visible" @cancel="visible = false" @ok="visible">
		<a :href="img" class="img-link-box" :download="equipment_number + '.png'">
			<img :src="img" alt="" />
		</a>
	</a-modal>
</template>

<script>
import { createQRCode } from '@/api/equipment'
export default {
	props: {
		equipment_id: {
			type: String,
			default: ''
		},
		equipment_number: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			visible: false,
			img: ''
		}
	},
	watch: {
		equipment_id(val) {
			if (val) {
				this.createQRCode()
			}
		}
	},
	async mounted() {},
	methods: {
		async createQRCode() {
			const res = await createQRCode({ equipment_id: this.equipment_id })
			if (res.code === 0) {
				this.img = res.data
			}
		}
	}
}
</script>

<style lang="less" scoped>
.img-link-box {
	display: block;
	img {
		width: 100%;
		display: block;
	}
}
</style>
