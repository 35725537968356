<template>
	<div class="app-box">
		<div class="title-box">
			<div class="line"></div>
			<p class="title">设备清单</p>
		</div>
		<a-divider style="margin-left: -10px" />
		<a-form-model class="form-search" :colon="false" layout="inline" :model="query" labelAlign="left"
			@submit="handleSearch" @submit.native.prevent>
			<a-row>
				<a-form-model-item style="float: right; margin-right: 0">
					<a-button type="primary" html-type="submit"> 查询 </a-button>
					<a-button @click="Reset">重置</a-button>
				</a-form-model-item>
				<a-form-model-item label="所属医院">
					<a-select placeholder="请选择" v-model="query.hospital_id">
						<a-select-option :value="0" :key="0">
							全部
						</a-select-option>
						<a-select-option :value="item.hospital_id" v-for="item in hospitals" :key="item.hospital_id">
							{{ item.name }}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="设备类型">
					<a-select placeholder="请选择" v-model="query.connect_type" @change="selectChange">
						<a-select-option :value="0" :key="0">
							全部
						</a-select-option>
						<a-select-option :value="2" :key="2">
							4G设备
						</a-select-option>
						<a-select-option :value="1" :key="1">
							WiFi设备
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="设备编号">
					<a-input v-model="query.equipment_number" placeholder="设备编号" />
				</a-form-model-item>
				<a-form-model-item label="硬件编号">
					<a-input v-model="query.hardware_number" placeholder="硬件编号" />
				</a-form-model-item>
				<a-form-model-item label="代理商">
					<a-input v-model="query.account_name" placeholder="代理商" />
				</a-form-model-item>
			</a-row>
			<a-row style="margin-top: 20px">
				<a-form-model-item label="设备状态">
					<a-checkbox-group v-model="checkValue" name="checkboxgroup" :options="checkOptions"
						@change="changeCheck" />
				</a-form-model-item>
			</a-row>
		</a-form-model>
		<a-row class="table-action-list" style="margin-top: 20px">
			<a-popconfirm title="是否解绑设备" ok-text="是" cancel-text="否" @confirm="toUnBind()">
				<a-button type="primary">解绑</a-button>
			</a-popconfirm>
			<a-button type="primary" style="margin-left: 10px" @click="toQrRecord()">打印二维码</a-button>
		</a-row>
		<a-table class="main-table" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
			:pagination="{
				current: query.page,
				total: pagetotal,
				showTotal: (pagetotal, range) => `共 ${pagetotal} 条数据`,
			}" @change="listChange" :columns="columns" :data-source="data" rowKey="equipment_id" style="margin-top: 14px"
			v-viewer>
			<span slot="area" slot-scope="text, record">
				{{ record.hospital_name + '-' + record.department_name + '-' + record.hos_number }}
			</span>
			<span slot="qr_img" slot-scope="text, record">
				<!-- <img :src="record.qr_img" class="img-qr" /> -->
				<a-icon class="code-icon" type="qrcode" @click="showQrCode(record)" />
			</span>
			<span slot="use_status" slot-scope="text, record">
				<a-tag :color="record.use_status === 1 ? '#74CE78' : '#757776'">
					{{ record.use_status === 1 ? '租用中' : '未租用' }}
				</a-tag>
			</span>
			<span slot="equipment_status" slot-scope="text, record">
				<a-tag :color="record.equipment_status === 1 ? '#74CE78' : '#757776'">
					{{ record.equipment_status === 1 ? '在线' : '离线' }}
				</a-tag>
			</span>
			<span slot="type" slot-scope="text, record">
				{{ getName(classListNoPage, record.type, "class_id", "name") }}
			</span>
			<span slot="all_rent_count" slot-scope="text, record">
				<a-space>
					<a @click="toOrder(record)">{{ record.all_rent_count }}次</a>
				</a-space>
			</span>
			<span slot="qr_code" slot-scope="text">
				<img :src="text" class="img-qr" />
			</span>
			<span slot="iot_card" slot-scope="text,record">
				<a v-if="record.type == 2" @click="showModal(record)">
					查看
				</a>
				<template v-if="record.type == 1">-</template>
			</span>
			<span slot="action" slot-scope="text, record">
				<a-space>
					<a @click="toEdit(record)">编辑</a>
					<a @click="showShareModal(record)">分红</a>
					<a @click="showSetModal(record)">配置</a>
					<a @click="showRent(record)">租用</a>
					<a style="color: #E0352B" @click="toStop(record)">归还</a>
					<a-popconfirm v-if="record.type == 1" title="WiFi账密推送" ok-text="是" cancel-text="否"
						@confirm="push(record.equipment_id)">
						<a style="color: #E0352B">推送WiFi</a>
					</a-popconfirm>
				</a-space>
			</span>
		</a-table>
		<a-modal title="查看ICCID" :visible="visible_iccid" @ok="handleOk" @cancel="handleCancel" cancelText="关闭" okText="刷新">
			<p>{{ showIccid }}</p>
		</a-modal>
		<!-- 查看 -->
		<!-- <a-modal title="ICCID" :visible="icc_visible" cancel-text="关闭" @cancel="handleCancelBind">
			<a-form-model :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" ref="bindForm">
				<a-form-model-item ref="name" label="名称" prop="name">
					<a-input v-model="iccid" @blur="() => {
						$refs.name.onFieldBlur();
					}
						" placeholder="暂无，请联系管理员更新" disabled />
				</a-form-model-item>
			</a-form-model>
		</a-modal> -->
		<ShareModal ref="shareModal" @updateData="handleSearch" :equipment_id="shareId"></ShareModal>
		<!-- 租赁时间弹窗 -->
		<RentModal :equipment_id="rentId" @success="handleSearch" ref="rentModal" />
		<!-- 设备配置 -->
		<EquipmentSetModal ref="EquipmentSetModal" @success="handleSearch" :equipment_id="equipmentSetId" />
		<!-- 设备编辑 -->

		<UpdateModal ref="updateModal" :equipment_id="updateId" :info="equipment_info" @success="getList" />

		<EditBindModal ref="editBindModal" @updateData="getList" :info="bindInfo" />

		<QrcodeModal :equipment_id="qrcodeId" :equipment_number="qr_number" ref="qrcodeModal" />
	</div>
</template>

<script>
import { domain } from '@/siteInfo.js'
import ShareModal from './components/shareModal.vue'

import RentModal from '../../components/rentModal.vue'
import EquipmentSetModal from '../../components/equipmentSetModal.vue'
import UpdateModal from './components/updateModal.vue'
import { equipmentBindInfo } from '@/api/equipment.js'
import EditBindModal from './components/editBindModal.vue'
import QrcodeModal from './components/qrcode.vue'
import { Modal } from 'ant-design-vue';
export default {
	components: { ShareModal, RentModal, EquipmentSetModal, UpdateModal, EditBindModal, QrcodeModal },
	data() {
		return {
			shareId: '', //用来分红用的id
			query: {
				page: 1,
				limit: 10
			},
			customer_id: null,
			visible: false,
			icc_visible: false,
			iccid: "",
			form: {},
			classListNoPage: [],
			hospitals: [],
			pagetotal: 0,
			checkValue: [],
			equipmentSetId: '',
			checkOptions: [
				{
					label: '在线',
					value: 1
				},
				{
					label: '离线',
					value: 2
				},
				{
					label: '租用中',
					value: 3
				},
				{
					label: '未租用',
					value: 4
				}
			],
			columns: [
				{
					title: '序号',
					customRender: (text, record, index) => index + 1
				},
				{
					title: '设备编号',
					dataIndex: 'equipment_number'
				},
				{
					title: '类型',
					scopedSlots: {
						customRender: 'type'
					}
				},
				{
					title: '硬件编号',
					dataIndex: 'hardware_number'
				},
				{
					title: 'ICCID',
					scopedSlots: {
						customRender: 'iot_card'
					}
				},
				{
					title: '代理商',
					dataIndex: 'account_agent_name'
				},
				{
					title: '所属医院',
					scopedSlots: {
						customRender: 'area'
					}
				},
				{
					title: '护士长',
					dataIndex: 'account_nurse_name'
				},
				{
					title: '运营',
					dataIndex: 'account_operator_name'
				},
				{
					title: '租用',
					scopedSlots: {
						customRender: 'use_status'
					}
				},
				{
					title: '在线状态',
					scopedSlots: {
						customRender: 'equipment_status'
					}
				},
				{
					title: '租用(次)',
					scopedSlots: {
						customRender: 'all_rent_count'
					}
				},
				{
					title: '使用(分)',
					dataIndex: 'all_rent_time'
				},
				{
					title: '二维码',
					scopedSlots: {
						customRender: 'qr_img'
					}
				},
				{
					title: '操作',
					scopedSlots: {
						customRender: 'action'
					}
				}
			],
			data: [],
			selectedRowKeys: [],
			rentId: '',
			updateId: '', //用来编辑的机器ID
			equipment_info: {},
			bindInfo: {},
			qrcodeId: '',
			qr_number: '',
			showIccid: "",
			visible_iccid: false,
			iccid_equipment_id: null


		}
	},
	created() {
		this.customer_id = this.$route.query.cid
		this.getList()
		this.getHospitals()
		this.getEquipmentClass()
	},
	methods: {
		getEquipmentClass() {
			this.$post('equipmentClass/listNoPage', {})
				.then(res => {
					let { code, msg, data } = res
					if (code == 0) {
						this.classListNoPage = data
						console.log("data", this.classListNoPage)
					} else {
						this.$message.error(msg, 1.5)
					}
				})
		},
		getName(arr, id, idName, lableName) { //通过id获取数组名称
			if (id || id == '0') {
				let searId = id.toString()
				const index = arr.findIndex((role) => role[idName] === searId)
				return index >= 0 ? arr[index][lableName] : undefined
			} else {
				return undefined
			}
		},
		showModal(info) {
			this.visible_iccid = true
			this.iccid_equipment_id = info.equipment_id
			this.showIccid = info["iot_card"] == "" ? "暂无，请联系管理员" : info["iot_card"];
		},
		handleCancel() {
			this.showIccid = ""
			this.iccid_equipment_id = ""
			this.visible_iccid = false
		},
		//刷新
		handleOk() {
			this.$post('equipment/refreshIot', { "equipment_id": this.iccid_equipment_id })
				.then(res => {
					let { code, msg, data } = res
					if (code == 0) {
						this.showIccid = data
						this.$message.success("更新成功", 1.5)
						this.getList()
					} else {
						this.$message.error(msg, 1.5)
					}
				})
		},
		push(id) {
			this.$post(domain + '/admin/v1/equipment/wifiPushByEquipment', { equipment_id: id }).then(res => {
				if (res.code === 0) {
					this.$message.success(res.msg)
					this.getList()
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		toOrder(item) {
			if (item.all_rent_count > 0) {
				this.$router.push({
					path: '/orderList',
					query: {
						eid: item.equipment_id
					}
				})
			} else {
				this.$message.warn("暂未产生订单")
			}
		},
		del(id) {
			console.log(id)
			this.$confirm({
				title: '确认',
				content: '确认删除该设备吗',
				onOk: () => {
					this.$post(domain + '/admin/v1/equipment', {
						equipment_id: id
					}).then(res => {
						if (res.code === 0) {
							this.$message.success(res.msg)
							this.getList()
						} else {
							this.$message.error(res.msg)
						}
					})
				},
				onCancel: () => { }
			})
		},
		toStop(item) {
			this.$post("equipment/equipmentRent", {
				'equipment_id': item.equipment_id,
				'type': 2
			})
				.then(res => {
					let { code, msg, data } = res
					this.confirmLoading = false
					if (code == 0) {
						this.$message.success(msg, 1.5)
					} else {
						this.$message.error(msg, 1.5)
					}
				})
				.catch(err => {
					this.confirmLoading = false
				})

		},
		getHospitals() {
			this.$post(domain + '/admin/v1/hospital/hospitalSelect', {}).then(res => {
				if (res.code === 0) {
					this.hospitals = res.data
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		Reset() {
			this.customer_id = null
			this.query = {
				page: 1,
				limit: this.query.limit
			}
			this.getList()
		},
		equipmentOn(id) {
			this.$post(domain + '/admin/v1/equipment/equipment/on', {
				equipment_id: id
			}).then(res => {
				if (res.code === 0) {
					console.log(res)
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		toSet(item) { },
		toQrRecord() { },
		toUnBind(item) {
			console.log('我选中了啥', this.selectedRowKeys)
			if (this.selectedRowKeys.length <= 0) {
				this.$message.error('请选中设备进行解绑')
			} else {
				let obj = {
					equipment_ids: this.selectedRowKeys
				}
				this.$post('equipment/equipmentUnbind', obj)
					.then(res => {
						let { code, msg, data } = res
						if (code == 0) {
							this.$message.success(msg, 4)
							this.getList()
						} else {
							this.$message.error(msg, 1.5)
						}
					})
					.catch(err => {
						this.confirmLoading = false
					})
			}
		},
		toChangeList(id) {
			this.$router.push({
				path: '/changeList',
				query: {
					id: id
				}
			})
		},
		toContrastList(id) {
			this.$router.push({
				path: '/contrastList',
				query: {
					id: id
				}
			})
		},
		getList() {
			this.$post(domain + '/admin/v1/equipment/equipmentList', this.query).then(res => {
				if (res.code === 0) {
					this.data = res.data.list
					this.pagetotal = res.data.total
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		listChange(e) {
			this.query.page = e.current
			this.getList()
		},
		handleSearch(query) {
			this.customer_id = null
			this.query.page = 1
			this.getList()
			console.log(query)
		},
		changeCheck(value) {
			this.query.page = 1
			this.query.type = value
			this.getList()
		},
		onSelectChange(selectedRowKeys) {
			this.selectedRowKeys = selectedRowKeys
		},
		showShareModal(item) {
			console.log(item)
			this.shareId = item.equipment_id
			this.$refs.shareModal.visible = true
		},
		showRent(item) {
			console.log(item)
			this.rentId = item.equipment_id
			this.$refs.rentModal.visible = true
		},
		showSetModal(item) {
			this.equipmentSetId = item.equipment_id
			this.$refs.EquipmentSetModal.visible = true
		},
		async toEdit(item) {
			// 获取绑定详情
			const res = await equipmentBindInfo({ equipment_id: item.equipment_id })
			if (res.code === 0) {
				this.bindInfo = {
					equipment_id: item.equipment_id,
					plan_id: res.data.plan_id,
					hospital_id: res.data.hospital_id,
					department_id: res.data.department_id,
					account_nurse_id: res.data.account_nurse_id,
					account_hospital_id: res.data.account_hospital_id,
					account_agent_id: res.data.account_agent_id,
					account_operator_id: res.data.account_operator_id
				}
				this.$refs.editBindModal.visible = true
			} else {
				this.$message.error(res.msg || '设备编辑详情获取失败')
			}
		},
		showQrCode(item) {
			this.qrcodeId = item.equipment_id
			this.qr_number = item.equipment_number
			this.$refs.qrcodeModal.visible = true
		}
	}
}
</script>

<style lang="less" scoped>
.title-box {
	display: flex;
	align-items: center;
	margin-left: 8px;

	.line {
		width: 5px;
		height: 14px;
		background-color: #4e80f8;
	}

	.title {
		color: #383f50;
		font-size: 16px;
		margin-left: 5px;
	}
}

.code-icon {
	display: block;
	font-size: 24px;
	color: #4e80f8;
}

.img-qr {
	height: 30px;
	cursor: pointer;
}
</style>

<style lang="less">
.form-search {
	.ant-form-item-label {
		width: 70px;
	}
}
</style>
